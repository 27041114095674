import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import styled from "styled-components"
import Masonry from "react-masonry-css"
import "src/components/core/layout/masonry.css"
import Layout from "src/components/core/layout/layout"
import PhotoTile from "src/components/photos/photo-tile"
import "src/components/photos/lightbox.scss"
import Img from 'gatsby-image';
import SEO from 'src/components/seo/SEO';

export const query = graphql`
  query PersonalQuery {
    allPrismicModel {
      nodes {
        data {
          brand {
            text
          }
          name {
            text
          }
          photo {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
      }
    }
  }
`
const PersonalPhoto = styled.div`
padding-bottom: 2rem;
@media (min-width: 1200px){
width: 75%;
margin: auto;
}
`
const PhotoWrapper = styled.div`
width: 100%;
height: 100%;
.gatsby-image-wrapper{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
`
const ContainerPersonal = styled(Container)`
width: 100%;

`
const TextWrapper = styled.div``
const TextElement = styled.h3`

`
const ProjectName = styled.h3`
font-weight: bold;
`

const ModelName = styled.h3`
font-size: 1.2rem;
`

export default ({ data }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 3,
    500: 2,
  }

  const photos = []
  data.allPrismicModel.nodes.forEach(model =>
    photos.push(
      <div key={model.data.photo ? model.data.photo.md : null}>
        <PersonalPhoto>
          <PhotoWrapper>
            <Img fluid={model.data.photo ? model.data.photo.fluid : null} />
          </PhotoWrapper>
          <TextWrapper>
            <ModelName>{model.data.name ? model.data.name.text : null}</ModelName>
            <ProjectName>{model.data.brand ? model.data.brand.text : null}</ProjectName>
          </TextWrapper>
        </PersonalPhoto>
      </div>
    )
  )
  return (
      <>
      <SEO  title="Personal"/>
    <Layout>
    <ContainerPersonal>
      {photos.map(photo=>photo)}
    </ContainerPersonal>
    </Layout>
    </>
  )
}
